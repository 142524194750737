/* eslint-disable @typescript-eslint/no-unused-vars */
import Vue from 'vue';
import { abPlugin } from '@webplatform/asbis-ui';
import { forEachSections } from '@webplatform/asbis-ui/dist/sections';
import '@webplatform/asbis-ui/dist/assets/scss/reset.scss';
import '@webplatform/asbis-ui/dist/assets/scss/index.scss';
import '@webplatform/asbis-ui/dist/assets/themes/canyon/theme.scss';
import '@webplatform/asbis-ui/dist/assets/themes/canyon-gaming/theme.scss';

forEachSections((sectionName, sectionComponent) => {
  Vue.component(sectionName, sectionComponent);
});

Vue.config.ignoredElements = [
  'swiper-container',
  'swiper-slide',
  'sl-tab',
  'sl-tab-panel',
  'sl-tab-group',
];

// eslint-disable-next-line func-names
export default function ({ $gsap, $ScrollTrigger }) {
  Vue.use(abPlugin, {
    preset: 'canyon',
    components: {
      AbButton: {
        props: {
          multilayered: {
            default: true,
          },
        },
      },
    },
    gsap: $gsap,
    ScrollTrigger: $ScrollTrigger,
  });
}
